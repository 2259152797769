var config = {};
config.env = 'test';

//AWS
config.aws = {};
config.aws.apiRoot = "https://test-api-mice.amgen.com/";
// config.aws.apiRoot = "https://eawxb0xse4.execute-api.us-west-2.amazonaws.com/test/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'I3MmaICTLN78jx3ugD5h98vOsDaYkzP879FSpPF3';

export default config;
